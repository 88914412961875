import React, { Fragment } from "react";
import { Formik } from "formik";
import Error from "./Error";
import * as Yup from "yup";
import { collection, addDoc } from 'firebase/firestore/lite';
import "../welcomeform/form.css";

import  { db } from '../firebase';
import { useHistory } from "react-router-dom";



const validationSchema = Yup.object().shape({
fname: Yup.string()
.min(3, "Please have 5 character")
.max(25, "Must have 25 max characters")
.required("Please enter your first name"),
lname: Yup.string()
.min(3, "Please have 5 character")
.max(25, "Please have 25 max characters")
.required("Please enter you last name"),
email: Yup.string()
.email("Please be a valid email address")
.required("Please enter an email"),
phone: Yup.number()
.integer()
.min(11, "Please be a valid phone number")
.required("Please enter your contact number"),
address: Yup.string().required("Please enter your address"),
city: Yup.string().required("please enter your current city"),
branch: Yup.string().required("Please Select your Branch"),
});
const MembershipForm = () => {
  const history = useHistory();

  const [selectedValue, setSelectedValue] = React.useState('');   
  const [selectedPosition, setSelectedPosition] = React.useState('');
  const [showAdd, setShowAdd] = React.useState(false);
  const [participantName, setParticipantName] = React.useState('');
  const [participantPhone, setParticipantPhone] = React.useState('');
  const [participants, setParticipants] = React.useState([]); 

  const handleBranchChange = (event) => {
    setSelectedValue(event.target.value);   
  };

  const handlePosition = (event) => {
    setSelectedPosition(event.target.value);   
  };

  const AddParticipant = () => {
    setShowAdd(!showAdd);
  }

  const PustParticipant = () => {
    if(participantName == '' || participantPhone === ''){
      return alert('All Participant details are required/')
    }
    setParticipants([{Name: participantName, Phone: participantPhone}, ...participants]);
    setShowAdd(!showAdd);
    return alert('Participant details successfully added');
  }

  console.log('participants', participants)

return (
<Fragment>
<div className="col-md-8 col-lg-8 col-sm-12 col-x-12 col-md-offset-2">
<section className="site-container padding-tb welcomeform">
<h3 className="wow fadeInDown" data-wow-delay="0.4s">
<strong>PLEASE FILL THE FORM BELOW:</strong>
</h3>
<section className="card">
<Formik
  initialValues={{
  fname: "",
  lname: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  branch: '',
  }}
    validationSchema={validationSchema}
    onSubmit={async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
    values.participants = participants;
    const collectionRef = collection(db, 'unleash2024');
    await addDoc(collectionRef, values);
    resetForm();
    setSubmitting(false);
    return history.push('/success', true);
    } catch (error) {
      console.log('error', error)
    }
  }}
>
{({
values,
errors,
handleChange,
handleBlur,
touched,
handleSubmit,
isSubmitting,
resetForm,
}) => (
<form className="form" onSubmit={handleSubmit}>
{/* FIRST NAME && LASTNAME STARTS */}

<div className="row">
<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
<div
  className="form__wrapper wow fadeInDown"
  data-wow-delay="0.5s"
>
  <input
    type="text"
    id="fname"
    name="fname"
    className={
      touched.fname && errors.fname
        ? "form__input error"
        : "form__input"
    }
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.fname}
  />
  <label className="form__label" htmlFor="First Name">
    <span className="form__label-content">
      First Name
    </span>
  </label>
  <Error
    touched={touched.fname}
    message={errors.fname}
  />
</div>
</div>
<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
<div
  className="form__wrapper wow fadeInDown"
  data-wow-delay="0.5s"
>
  <input
    type="text"
    id="lname"
    name="lname"
    onChange={handleChange}
    onBlur={handleBlur}
    value={values.lname}
    className={
      touched.lname && errors.lname
        ? "form__input error"
        : "form__input"
    }
  />
  <label className="form__label" htmlFor="Last Name">
    <span className="form__label-content">
      Last Name
    </span>
  </label>
  <Error
    touched={touched.lname}
    message={errors.lname}
  />
</div>
</div>
</div>
{/* FIRST NAME && LASTNAME ENDS */}

{/* EMAIL && PHONE NUMBER */}
<div className="row">
  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
    <div
      className="form__wrapper wow fadeInDown"
      data-wow-delay="0.6s"
    >
      <input
        type="email"
        id="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={
          touched.email && errors.email
            ? "form__input error"
            : "form__input"
        }
      />
      <label className="form__label" htmlFor="email">
        <span className="form__label-content">Email</span>
      </label>
      <Error
        touched={touched.email}
        message={errors.email}
      />
    </div>
  </div>
  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div
        className="form__wrapper wow fadeInDown"
        data-wow-delay="0.6s"
      >
        <input
          type="number"
          id="phone"
          name="phone"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          className={
            touched.phone && errors.phone
              ? "form__input error"
              : "form__input"
          }
        />
        <label className="form__label" htmlFor="phone">
          <span className="form__label-content">
            {" "}
            Phone (XXX) XXX-XXXX
          </span>
        </label>
        <Error
          touched={touched.phone}
          message={errors.phone}
        />
      </div>
  </div>
</div>
<div
className="form__wrapper wow fadeInDown"
data-wow-delay="0.6s"
>
<input
type="text"
id="address"
name="address"
onChange={handleChange}
onBlur={handleBlur}
value={values.address}
className={
  touched.address && errors.address
    ? "form__input error"
    : "form__input"
}
/>
<label className="form__label" htmlFor="address">
<span className="form__label-content">Adress</span>
</label>
<Error
touched={touched.address}
message={errors.address}
/>
</div>
<div className="row">
  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div
        className="form__wrapper wow fadeInDown"
        data-wow-delay="0.6s"
      >
        <input
          type="text"
          id="city"
          name="city"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          className={
            touched.city && errors.city
              ? "form__input error"
              : "form__input"
          }
        />
        <label className="form__label" htmlFor="phone">
          <span className="form__label-content"> City</span>
        </label>
        <Error touched={touched.city} message={errors.city} />
      </div>
  </div>


  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div
        className="form__wrapper wow fadeInDown"
        data-wow-delay="0.6s"
      >
        <label class="control-label" for="branch">Branch</label>
            <select value={selectedValue} onChange={(evt) => {
              handleBranchChange(evt);
              handleChange(evt)
            }} className="form-control" name="branch">
              <option value=''>Select</option>
              <option value='Aba'>Aba</option>
              <option value="mainland-HQ">Mainland-HQ</option>
              <option value="Ikorodu">Ikorodu</option>
              <option value="Unilag">Unilag</option>
              <option value="Majek">Majek</option>
              <option value="Island-HQ">Island-HQ</option>
              <option value='Ketu'>Ketu</option>
              <option value="Port-Harcourt">Port Harcourt</option>
              <option value="Umuahia">Umuahia</option>
              <option value="Uniport">Uniport</option>
              <option value="Yaba">Yaba</option>
              <option value="Guest">Guest</option>
            </select>
        <Error touched={touched.branch} message={errors.branch} />
      </div>
  </div>

  
</div>

{selectedValue === 'Guest' ? (
  <>
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div
          className="form__wrapper wow fadeInDown"
          data-wow-delay="0.6s"
        >
          <input
            type="churchName"
            id="churchName"
            name="churchName"
            onChange={handleChange}
            onBlur={handleBlur}
            required
            value={values.churchName}
            className={
              touched.churchName && errors.churchName
                ? "form__input error"
                : "form__input"
            }
          />
          <label className="form__label" htmlFor="churchName">
            <span className="form__label-content">Name of Church</span>
          </label>
          <Error
            touched={touched.churchName}
            message={errors.churchName}
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div
            className="form__wrapper wow fadeInDown"
            data-wow-delay="0.6s"
          >
            <label class="control-label" for="branch">Position</label>
            <select value={selectedPosition} onChange={handlePosition} className="form-control" name="position">
              <option value='Aba'>General Overseer</option>
              <option value="Minister">Minister</option>
              <option value="Pastor">Pastor</option>
              <option value="worker">Church Worker</option>
            </select>
            <Error touched={touched.branch} message={errors.branch} />
          </div>
      </div>
  </div>

            <div className="row" style={{marginTop: 10}}>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <button
                  className="btn  btn-primary"
                  type="button"
                  onClick={AddParticipant}
                >
                  Add Participant
                </button>
              </div>
            </div>

            {showAdd ? (
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-5">
                  <input
                    type="participantName"
                    id="participantName"
                    name="participantName"
                    onChange={(evt) =>  setParticipantName(evt.target.value)}
                    placeholder="Participant Name"
                    onBlur={handleBlur}
                    value={values.participantName}
                    className={
                      touched.participant && errors.participantName
                        ? "form__input error"
                        : "form__input"
                    }
                  />
                </div>

                <div className="col-xs-12 col-sm-12 col-md-5">
                  <input
                    type="participantPhone"
                    id="participantPhone"
                    name="participantPhone"
                    onChange={(evt) =>  setParticipantPhone(evt.target.value)}
                    placeholder="Participant Phone"
                    onBlur={handleBlur}
                    value={values.participantPhone}
                    className={
                      touched.participantPhone && errors.participantPhone
                        ? "form__input error"
                        : "form__input"
                    }
                  />
                </div>
                
                <div className="col-xs-12 col-sm-12 col-md-2">
                <button
                  className="btn  btn-primary"
                  type="button"
                  onClick={PustParticipant}
                >
                  Add
                </button>
                </div>
              </div>
            ): null}

  </>
): null}


<div className="col-xs-6">
{" "}
<div
className="form__wrapper--submit wow fadeInLeft"
data-wow-delay="0.7s"
>
<div className="form__input-submit">
  <button
    className="btn btn-block btn-primary"
    type="submit"
    disabled={isSubmitting}
  >
    Register
  </button>
</div>
</div>
</div>

  <div className="col-xs-6">
    <div
    className="form__wrapper--submit wow fadeInLeft"
    data-wow-delay="0.7s"
    >
      <div className="form__input-submit">
        <button
          className="btn btn-block btn-danger"
          type="reset"
          onClick={resetForm}
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</form>
)}
</Formik>
</section>
</section>
</div>
</Fragment>
);
}


export default MembershipForm;
