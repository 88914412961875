import React from "react";
import { Link, useHistory } from "react-router-dom";
import serializeForm from "form-serialize";
import { createUserWithEmailAndPassword } from "firebase/auth";
import 'firebase/firestore';
import { doc, setDoc } from "firebase/firestore"; 
import { auth, db } from "../firebase";



const MembeRegister = () => {
  const history = useHistory();
  const [isLoading, setLoading] = React.useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    const regValues = serializeForm(e.target, { hash: true });
    const { email, password, firstname, lastname, whatsapp, other, state, city, address } = regValues;
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const user = userCredential.user;
      const userId =  user.uid;
      const data = { firstname, lastname, whatsapp, other, state, city, address, email, inSchool: false, isPartner: false }
      const partner = { category: 0, monthlyFee: 0, prtnerId: ''}
      // store user details in users collection 
      const userRef = doc(db, 'users', userId);
      const PartnerRef = doc(db, 'partnership', userId);
     
      await setDoc(userRef, data);
      await setDoc(PartnerRef, partner);
      // notification on successful reg
      return history.push('/login');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log('error->', errorMessage)
    }
  };

  return (
    <div className="container ">
      <div className="col-md-12 col-sm-12 col-lg-12 mt-5 login-div">
        <div className="container login-container">
          <div className="row">
            <div className="col-md-6 login-form-1 reg">
              <h3>
                <div className="img-logo">
                  <img src="/img/round-logo.png" alt="gloryplus-logo" />
                </div>
              </h3>
              <form>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <div className="form-group"></div>
                <div className="form-group">
                  <Link to="/login" className="ForgetPwd">
                    Already a member ? <u>Click here to login</u>
                  </Link>
                  <h4>
                    We are glad to have you here, please register and proceed
                    to login page.
                  </h4>
                </div>
              </form>
            </div>

            <div className="col-md-6 login-form-2">
              <h3> Registration</h3>
              <form onSubmit={handleRegister}>
              <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Firstname *"
                    required
                    name="firstname"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Lastname *"
                    required
                    name="lastname"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="WhatsApp  number*"
                    name="whatsapp"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="Other Number*"
                    required
                    name="other"
                  />
                </div>
              
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email *"
                    required
                    name="email"
                  />
                </div>
               
                <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="State *"
                    required
                    name="state"
                  />
                </div>
                    <div className="form-group">
                  <input
                    type="input"
                    className="form-control"
                    placeholder="City *"
                    required
                    name="city"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Your Address *"
                    required
                    name="address"
                  ></textarea>
                </div> 

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Your Password *"
                    required
                    name="password"
                  />
                </div>

                <div className="form-group">
                  <input
                    type="submit"
                    className="btnSubmit"
                    value="Register"
                  />
                </div>
                <div className="form-group">
                  {/* <Link to="#" className="ForgetPwd" value="Login">
                  
                </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default MembeRegister;
