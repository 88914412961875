import React, { Component } from "react";
import SlideImg from "./slides";
import "./style.css";
const content = [
  {
    title: " WELCOME TO CHURCH",
    description: "HIS GLORY IS MADE MANIFEST IN HIS PEOPLE",
    button1: "JOIN US",
    button2: "LIVESTREAM",
    image: "img/slider/rev&mumSmile.jpg",
    direction: "slide-text slide_style_center",
    status: "active"
  },
  {
    title: "LIVING IN GOD'S GLORY",
    description: "A GLORIOUS PEOPLE WITH A GLORIOUS MANDATE",
    button1: "JOIN US",
    button2: "LIVESTREAM",
    image: "img/slider/gpa.jpg",
    direction: "slide-text slide_style_left"
  },
  {
    title: "GLORIOUS CHOIR",
    description: "IN HIS PRESENCE THERE IS FULLNESS OF JOY",
    button1: "JOIN US",
    button2: "LIVESTREAM",
    image: "img/slider/choir1.jpeg",
    direction: "slide-text slide_style_right"
  },
  {
    title: "IN HIS PRESENCE",
    description: "HIS GLORY IS MADE MANIFEST IN HIS PEOPLE",
    button1: "JOIN US",
    button2: "LIVESTREAM",
    image: "img/slider/mainChurch.jpg",
    direction: "slide-text slide_style_left"
  }
];
class Hero extends Component {
  render() {
    return (
      <div className="cont">
        <div
          id="bootstrap-touch-slider"
          className="carousel bs-slider slide  control-round indicators-line"
          data-ride="carousel"
          data-pause="hover"
          data-interval="5000"
        >
          <video id="background-video" className="embed-responsive-item herovid" loop autoPlay playsinline  poster="img/slider/rev&mumSmile.jpg" muted>
            <source src="home.mp4" type="video/mp4"/>
          </video> 
        </div>
      </div>
    );
  }
}

export default Hero;
