
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBHTowqYB2dkw3QKmrrwrref8qgVztPkdI",
    authDomain: "gloryplusintl-acd3f.firebaseapp.com",
    databaseURL: "https://gloryplusintl-acd3f.firebaseio.com",
    projectId: "gloryplusintl-acd3f",
    storageBucket: "gloryplusintl-acd3f.appspot.com",
    messagingSenderId: "243368275163",
    appId: "1:243368275163:web:980fb542cdf08e2ed9f0fe",
    measurementId: "G-C6V3MT26VB"
  
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);



  export {
    db,
    auth,
    doc,
    getDoc
  };
