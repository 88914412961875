import React, { Fragment } from "react";
import { Formik } from "formik";
import Error from "./Error";
import * as Yup from "yup";
import { collection, addDoc } from 'firebase/firestore/lite';
import  { db } from '../firebase';

import "../welcomeform/form.css";
import { useHistory } from "react-router-dom";


const validationSchema = Yup.object().shape({
fname: Yup.string()
.max(25, "Must have 25 max characters")
.required("Please enter your first name"),
lname: Yup.string()
.max(25, "Please have 25 max characters")
.required("Please enter you last name"),
mobile: Yup.number()
.integer()
.min(11, "Please be a valid phone number")
.required("Please enter your contact number"),
whatsapp: Yup.number()
.integer()
.min(11, "Please be a valid phone number")
.required("Please enter your contact number"),
address: Yup.string().required("Please enter your address"),
state: Yup.string().required("please enter your current state"),
occupation: Yup.string().required("please enter your occupation"),
});
const PhInvasion = () => {
  const history = useHistory();
      return (
        <Fragment>
          <div className="col-md-8 col-lg-8 col-sm-12 col-x-12 col-md-offset-2">
            <section className="site-container padding-tb welcomeform">
              <section className="">
                <Formik
                initialValues={{
                fname: "",
                lname: "",
                whatsapp: "",
                mobile: "",
                address: "",
                state: "",
                occupation: ""
                }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      const collectionRef = collection(db, 'phinvasion');
                      await addDoc(collectionRef, values);
                      resetForm();
                      setSubmitting(false);
                      return history.push('/success', true);
         
                    }}
                    >
                    {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    }) => (
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                              <img src="img/ph.jpeg" className="img-responsive" style={{ marginTop: 20 }} />
                            </div>

                            <div className="col-md-6">
                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="fname"
                                    name="fname"
                                    className={
                                      touched.fname && errors.fname
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.fname}
                                  />
                                  <label className="form__label" htmlFor="First Name">
                                    <span className="form__label-content">
                                     Enter First Name
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.fname}
                                    message={errors.fname}
                                  />
                                </div>
                              </div>

                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="lname"
                                    name="lname"
                                    className={
                                      touched.lname && errors.lname
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lname}
                                  />
                                  <label className="form__label" htmlFor="Last Name">
                                    <span className="form__label-content">
                                     Enter Last Name
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.lname}
                                    message={errors.lname}
                                  />
                                </div>
                              </div>


                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="mobile"
                                    name="mobile"
                                    className={
                                      touched.mobile && errors.mobile
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.mobile}
                                  />
                                  <label className="form__label" htmlFor="Mobile">
                                    <span className="form__label-content">
                                     Enter Mobile Number
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.mobile}
                                    message={errors.mobile}
                                  />
                                </div>
                              </div>

                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="whatsapp"
                                    name="whatsapp"
                                    className={
                                      touched.whatsapp && errors.whatsapp
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.whatsapp}
                                  />
                                  <label className="form__label" htmlFor="WhatsApp Number">
                                    <span className="form__label-content">
                                     Enter WhatsApp Number
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.whatsapp}
                                    message={errors.whatsapp}
                                  />
                                </div>
                              </div>

                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    className={
                                      touched.state && errors.state
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.state}
                                  />
                                  <label className="form__label" htmlFor="First Name">
                                    <span className="form__label-content">
                                     Enter State
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.state}
                                    message={errors.state}
                                  />
                                </div>
                              </div>

                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    className={
                                      touched.address && errors.address
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address}
                                  />
                                  <label className="form__label" htmlFor="First Name">
                                    <span className="form__label-content">
                                     Enter Residential Address
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.address}
                                    message={errors.address}
                                  />
                                </div>
                              </div>


                              <div className="card">
                                <div
                                  className="form__wrapper wow fadeInDown"
                                  data-wow-delay="0.5s"
                                >
                                  <input
                                    type="text"
                                    id="occupation"
                                    name="occupation"
                                    className={
                                      touched.occupation && errors.occupation
                                        ? "form__input error"
                                        : "form__input"
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.occupation}
                                  />
                                  <label className="form__label" htmlFor="First Name">
                                    <span className="form__label-content">
                                     Enter title/Business/Occupation
                                    </span>
                                  </label>
                                  <Error
                                    touched={touched.occupation}
                                    message={errors.occupation}
                                  />
                                </div>
                              </div>


                              <button type="submit" className="btn btn-primary float-right">Submit</button>
                            </div>
                            
                        </div>
                    </div>
          
                  </form>
                    )}
                </Formik>
              </section>
            </section>
          </div>

        </Fragment>
    );
  }

export default PhInvasion;
