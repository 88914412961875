import { db } from "../custom/firebase";
import { doc, getDoc } from "firebase/firestore";
import { UPDATE_USER } from "./action-type";

export const updateUserRecord = (response) => ({ type: UPDATE_USER, response });

  export const reFetchUser =  (userId) =>  async (dispatch) =>{
    try {
      const docRef = doc(db, "users", userId); 
      const snapshot = await getDoc(docRef);
      const user = { ...snapshot.data(), userId };
      dispatch(updateUserRecord(user)); 
    } catch (error) {
    console.log('Error', error)
    }
  };