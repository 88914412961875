import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { useDispatch, useSelector } from "react-redux";
import { useHistory,  } from 'react-router-dom';
import { doc, setDoc, onSnapshot, collection, query, where, addDoc } from "firebase/firestore"; 
import { reFetchUser } from "../../actions/user";
import {
  Header,
  TopNav,
  Footer,
  PageInfo,
} from "../../custom";
import { db } from "../../custom/firebase";



const Partnership = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = getAuth();
  const [isActive, setActive] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [isLoggedIn, setLoginStatus] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const User = useSelector((state) => state?.User?.user || {});
  const name = `${User.lastname} ${User.firstname}` || '';
  const email = User.email || '';
  const phone = User.whatsapp || User.other || '';
  const state = User.state || '';
  const partnerId = User.userId;
  const [category, setCategory ] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [partnershipRecords, setRecords] = React.useState([]);

    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const uid = user.uid;  
          if(name === undefined || name === 'undefined') {
            return history.push('/login');
          }
          dispatch(reFetchUser(uid));
          setLoginStatus(uid);
          setLoading(false)
        } else {
          return history.push('/login');
        }
      });
    
    },[])


    useEffect(() => {
      fetchPartnership();
    },[partnerId])



    const config = {
      public_key: 'FLWPUBK_TEST-d4b41ed6db2e7497259993577dc1101f-X', // test
    //  public_key: 'FLWPUBK-1015c516ef299161a68606c4fbe3db36-X',
      tx_ref: Date.now(),
      amount: amount,
      currency: 'NGN',
      payment_options: 'card,ussd',
      customer: {
        email: email,
        phone_number: phone,
        name: name,
      },
      customizations: {
        title: 'Partnership',
        description: 'Payment for Partnership',
        logo: 'https://i.ibb.co/7kyghs0/gloryplus.png'
      },
      "subaccount": [
        {
          "id": "RS_35BF719FD4965A4337CFCB436A4C2EDE",
          transaction_charge_type: "100%"
        },
      ]
    };
    const handleFlutterPayment = useFlutterwave(config)

    const handleResponse = () => {
      if (amount !== '' && amount >= 100) {
        handleFlutterPayment({
          callback: async (response) => {
          //store in partners
            if (partnerId !== null) {
              const data = { amount, createdAt: Date.now(), partnerId, month }
              const myCollection = collection(db, 'partnership');
              await addDoc(myCollection, data);
              closePaymentModal();
            }
          },
        });
      } else {
        alert("Enter a valid Amount")
      }
    }

    const fetchPartnership = async () => {
      if(partnerId !== undefined) {
        const q = query(collection(db, "partnership"), where("partnerId", "==", partnerId));
        onSnapshot(q, (querySnapshot) => {
          const records = [];
          querySnapshot.forEach((doc) => {
            records.push(doc.data());
          });
          setRecords(records);
          console.log('records', records)
        });
      }
    }

    const createNumberOptions = () => {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const options = [];
      for (let i = 0; i < months.length; i++) {
        options.push(<option key={i} value={months[i]}>{`${months[i]}/${new Date().getFullYear()}`}</option>);
      }
      return options;
    }
    

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSetPartnerShip = async () => {
      if (category !== '' && isLoggedIn !== null) {
        const data = { category, isPartner: true }
        const userRef = doc(db, 'users', isLoggedIn);
        await setDoc(userRef, data, { merge: true });
        dispatch(reFetchUser(uid));
      } else {
        alert('Please Select your Category');
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>Partnership</title>
          <meta name="description" content="PartnerShip" />
        </Helmet>
        <TopNav />
        <Header />
        <PageInfo title="PartnerShip" bgPicture="url(img/bg-info/bg.jpg)" />
          {isLoading ? 
            <div className="container" style={{marginTop: 100}}>
                <div className="spinner">
                  <div className="fa-solid fa-spinner"></div>
                </div>
            </div>
          :
            <div className="container" style={{marginTop: 100}}>
              {(User.isPartner && isLoggedIn !== null) ? 
                <>
                  <div className="row" style={{marginBottom: 50, backgroundColor: '#F5F5F5', padding: 20 }}>
                    <div className="col-md-1" >
                      <img src="http://ssl.gstatic.com/accounts/ui/avatar_1x.png" className="avatar img-circle img-thumbnail" alt="avatar" />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <br/>
                      <h4>{capitalizeFirstLetter(name)}</h4>
                      <small><cite title={state} >  <i className="glyphicon glyphicon-map-marker">
                      </i> Nigeria, {state}</cite></small>
                      <p>
                          <i className="glyphicon glyphicon-envelope"></i> {email}
                          <br />
                          <i className="glyphicon glyphicon-phone"></i> {phone}
                      </p>
                    </div>
                    {!isActive ? (
                    <div className="col-sm-12 col-md-2" style={{marginTop: 60}}>
                      {/* <button type="button" class="btn btn-primary">Upload Receipt</button> {'   '} */}
                      <button type="button" className="btn btn-primary" onClick={()  => setActive(true)}>Pay Your PartnerShip</button>
                    </div>
                    ): (
                      <div className="row">
                        <div className="col-md-3 col-sm-12" style={{marginTop: 60}}>
                          <input type="text" className="form-control" placeholder="Enter Amount" onChange={(evt) => setAmount(Number(evt.target.value))} />
                        </div>
                        <div className="col-md-3 col-sm-12" style={{marginTop: 60}}>
                          <select className="form-control" onChange={(evt) => setMonth(evt.target.value)}>
                            {createNumberOptions()}
                          </select>
                        </div>

                        <div className="col-md-1 col-sm-2" style={{marginTop: 60}}>
                          <button type="button" className="btn btn-primary" onClick={() => handleResponse()}>Pay</button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">  
                        {partnershipRecords.length <= 0 ? 
                        (
                          <div className="alert alert-info text-center" role="alert">
                            No Partnership records found.
                          </div>
                        ): ( 
                          <table id="mytable" className="table table-bordred table-striped">     
                            <thead>
                              <th>Month</th>
                              <th>Date Paid</th>
                              <th>Amount Paid</th>           
                            </thead>
                            <tbody>
                            {partnershipRecords.map((r) =>  (
                              <tr>
                                <td>{r.month}</td>
                                <td>{moment(r.createdAt).format('YYYY-MM-DD')}</td>
                                <td>{r.amount}</td>
                              </tr>
                            ))} 
                            </tbody>
                          </table>
                        )}  
                      </div>
                    </div>
                  </div>
                </>
              :
                <div className="row" style={{marginBottom: 100, backgroundColor: '#F5F5F5', padding: 50 }}>
                
                    <b>Financial Commitment</b>
                    <hr />
                    <div className="row">
                      <div className="col-md-5 col-xs-12 col-sm-12" style={{ marginBottom: 10}}>
                        <select name="category" className="form-control" onChange={(event) => setCategory(Number(event.target.value))}>
                          <option value="">Select PartnerShip Category</option>
                          <option value="5000">Bronze (₦5,000 - ₦9,999)</option>
                          <option value="10000">Silver (₦10,000 - ₦19,999)</option>
                          <option value="20000">Gold (₦20,000 - ₦29,999)</option>
                          <option value="30000">Emerald (₦30,000 - ₦39,999)</option>
                            <option value="40000">Ruby (₦40,000 - ₦49,999)</option>
                          <option value="50000">Pink Diamond (₦50,000 - ₦99,999)</option>
                          <option value="100000">Blue Diamond (₦100,000 and Above)</option>
                        
                        </select>
                      </div>
                    
                      <div className="col-sm-12 col-md-2">
                      <button type="button" className="btn btn-primary" onClick={handleSetPartnerShip}>Submit</button>
                    </div>
                    </div>
                </div>

              }
            </div>
          }
        <Footer />
      </Fragment>
    );
  }

export default Partnership;
