import React, { Component, Fragment } from "react";
import { css } from "@emotion/core";
import Lottie from 'lottie-react';
import {
  Header,
  TopNav,
  Footer,
  PageInfo,
  NewsLetter,
} from "../../custom";
import animationData from './success.json';
import { Link } from "react-router-dom";
import { marginTop } from "../list-courses/style";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',   

  },
};


class Success extends Component {



  render() {
    return (
        <>
        <TopNav />
        <Header />
        <PageInfo title="" bgPicture="url(img/bg-info/login.png)" />
       
          <div className="container" style={{height: '70vh'}}>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4 text-center">
                  <Lottie animationData={animationData} loop={true}  height={200} width={200} />;
                  <h2 className="text-center text-success">Your Registration is Successful</h2>
                  <Link className="btn btn-success" style={{marginTop: 20}} to="/">Go Back</Link>
              </div>
            </div>
          </div>
       
        <NewsLetter />
        <Footer />
      </>
    );
  }
}

export default Success;
