import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  Header,
  TopNav,
  EventTimer,
  JoinUs,
  ServiceSection,
  NewsLetter,
  Footer,
  PastorSection,
  PageInfo
} from "../../custom";

class About extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>About</title>
          <meta name="description" content="About " />
        </Helmet>
        <TopNav />
        <Header />
        <PageInfo
          title="About Us"
          bgPicture="url(img/bg-info/aboutus-now.png)"
        />
        <div style={{ minHeight: "25vh" }}>
          <JoinUs />
          <ServiceSection />
          <section className="services-section spad" style={{backgroundColor: '#fff'}}>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 ">
                  <div className="service-box ">
                      <h4><i className="fa fa-fire"></i>WHO WE ARE</h4>
                      <p>
                        <p> 1. We are a Church that is excited to receive God’s Word. [Acts 2:41]</p>
                        <p> 2. We are a Church that builds our lives around scripture, with the scripture as our foundation. [Acts 2:42]</p>
                        <p> 3. We are a church that loves fellowshipping together, [Acts 2:42] Attending church fellowship and Getting to know one another.</p>
                        <p> 4. We are a church that loves to pray together (Heartfelt Prayers) [Acts 2:42]</p>
                        <p> 5. We are a Church where the Glory of God confirms His word with signs and wonders following. [Acts 2:43]</p>
                        <p> 6. We are a church that walks in love (Being there for one another); [Acts 2:44 - 45]</p>
                        <p> 7. We are a church that esteems meeting together in home cells [Acts 2:46]</p>
                        <p>  8. We are a Church that is filled with the Joy of the Lord (We have no worries) [Acts 2:46]</p>
                        <p> 9. We are a church with singleness of heart (We are Loyal and Devoted to the core) [Acts 2:46]</p>
                        <p>  10. We are a church whose hearts are filled with Praise (We constantly have reasons to testify of God’s goodness) [Acts 2:47]</p>
                      </p>
                    </div>
                    </div>
                    <div className="col-sm-6 ">
                      <div className="service-box">
                        <h4></h4>
                        <p>
                          <p>11. We are a Church that is Skillful in building relationships (With God and Men) [Acts 4:32] </p>
                          <p>12. We are a Church that is missionary focused (Acts 4:33) </p>
                          <p> 13. We are a church that is Generous (We lack nothing) [Acts 4:34&amp;36] </p>
                          <p> 14. We are Church that is system based (Acts 6:1-3) </p
                          ><p>15. We are a Church of Integrity (We walk in righteousness). (Acts 6:3) </p>
                          <p>16. We are a Church that is Obedient to God’s word. (Acts 6:7) </p>
                          <p>17. We are a Church that are Disciple makers. (Acts 6:7) </p>
                          <p>18. We are a Church that is filled with the Holy Spirit. (Acts 6:3) </p>
                          <p>19. We are a Church that walks in wisdom. (Acts 6:3) </p>
                          <p>20. We are a Church that walks by faith. (Acts 6:5) </p>
                          <p>21. We are a church that is bold in our approach to the kingdom mandate (Acts 4:31) </p>
                        </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        </section>
          <PastorSection />
        </div>
        <NewsLetter />
        <Footer />
      </Fragment>
    );
  }
}

export default About;
