import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  Header,
  TopNav,
  Footer,
  NewsLetter,
  PageInfo,
  PhInvasion,
} from "../../custom";

class Invasion extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Prophetic Invasion</title>
          <meta name="testimony" content="Port harcourt invasion Gloryplus International" />
        </Helmet>
        <TopNav />
        <Header />
        <PageInfo
          title="Port-Hartcourt Prophetic Invasion"
          bgPicture="url(img/bg-info/bg.jpg)"
        />
        <div
          style={{ minHeight: "150vh", alignItems: 'center' }}
          className="welcomediv"
        >
          <PhInvasion />
        </div>
        <NewsLetter />
        <Footer />
      </Fragment>
    );
  }
}

export default Invasion;
